import Vue from 'vue'
import TojoyRate from '@/components/common/rate'
Vue.use(TojoyRate)

const rateHtml = {
  name: 'rate-html',

  functional: true,

  props: {
    data: {
      require: true,
      type: Object
    },
    isOuter: {
      // 是否外部
      require: true,
      type: Boolean
    }
  },

  render: (h, { props, parent }) => {
    const { data, isOuter } = props
    const _mergeP = {
      desc: isOuter ? '内' : '外',
      nums: isOuter ? data.userEvaluateNum : data.customerEvaluateNum,
      rateList: isOuter ? data.userList : data.customerList,
      remarks: isOuter ? data.userRemarks : data.customerRemarks
    }
    const _title = `${_mergeP.desc}部客户满意度(${_mergeP.nums}人)：`

    return _mergeP?.rateList?.length ? (
      <div class='satisfaction-received-item__rate'>
        <h3 class='satisfaction-received-item__title block-mb-15'>{_title}</h3>
        {_mergeP.rateList.map((r, index) => {
          return <tojoy-rate rate={r.rate} disabled={true} title={r.title} />
        })}
        {_mergeP.remarks?.length ? (
          <article class='block-mt-20'>
            <h4 class='satisfaction-received-item__desc-title block-mb-10'>
              {_mergeP.remarks.length}条建议
            </h4>
            <ol class='ol__desc-list'>
              {_mergeP.remarks.map((item, index) => {
                return (
                  <li key={index}>
                    <span class='info-desc__dist'></span>
                    <p class='info-desc__text'>{item}</p>
                  </li>
                )
              })}
            </ol>
          </article>
        ) : (
          ''
        )}
      </div>
    ) : (
      ''
    )
  }
}

export default rateHtml
