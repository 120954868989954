import { handleScoreShowText } from './tool.js'

const complaintHtml = {
  name: 'complaint-html',

  functional: true,

  props: {
    data: {
      require: true,
      type: Object
    },
    isMine: {
      // 是我的 or 团队的
      require: true,
      type: Boolean
    },
    isShowApproveBtns: {
      type: Boolean,
      default: false
    },
    currentUserId: {
      type: [String, Number],
      default: ''
    }
  },

  render: (h, { props, parent }) => {
    const { data, isMine, isShowApproveBtns, currentUserId } = props
    const _textObj = data?.complaintOutputs
    function mineOpera(item) {
      function scoreShowStatus({ handleState, score }) {
        let showText = ''
        switch (handleState) {
          case 0:
            showText = `评价中`
            break
          case 1:
            showText = `${handleScoreShowText(score, false)}`
            break
          case 2:
            showText = `已过期`
            break
        }
        return showText
      }

      return item?.appealStatus ? (
        <el-button
          class='appeal-main-btn'
          type='text'
          onClick={() => parent.handleAppealShow(item)}
        >
          <span class='el-icon-chat-line-square'></span>
          申诉
        </el-button>
      ) : (
        <span class='score'>{scoreShowStatus(item)}</span>
      )
    }

    function underlingsOpera(item) {
      return item.handleState === 1 ? (
        <span class='score'>{handleScoreShowText(item?.score, false)}</span>
      ) : item.handleState === 0 ? (
        <span class='handle-state'>待处理</span>
      ) : item.handleState === 2 ? (
        <span class='handle-state'>已过期</span>
      ) : (
        ''
      )
    }

    function underlingsApproveBtn(item) {
      if (!isShowApproveBtns) return false

      // 如果状态非过期 且 审核用户是当前登录用户
      const currentUserData = item?.complaintAuditOutputs?.filter(
        cr => cr.state !== 2 && String(currentUserId) === String(cr.auditUserId)
      )

      if (!currentUserData.length) return false

      let btnsShow = null

      // 如果有未审批状态，优先显示未审批，否则显示我已审批-20210305
      btnsShow = currentUserData.find(c => c.state === 0)

      if (!btnsShow) {
        btnsShow = currentUserData.find(c => c.state === 1)
      }

      return (
        <div class='underlings-approve__btns'>
          <el-button plain size='medium' onClick={() => parent.contactMobile(item)}>
            联系客户
          </el-button>
          <el-button
            type='primary'
            size='medium'
            disabled={btnsShow.state === 1}
            onClick={() => parent.handleApproveShow(btnsShow, item)}
          >
            {btnsShow.state === 1 ? '我已审批' : '审批'}
          </el-button>
        </div>
      )
    }

    return _textObj?.length ? (
      <article class='satisfaction-received-item__textwrap'>
        <h3 class='satisfaction-received-item__desc-title block-mb-20'>{`${_textObj?.length ??
          0}条投诉`}</h3>
        {_textObj.map(item => {
          return (
            <div class='satisfaction-received-item__custom'>
              <h4 class='satisfaction-received-item__desc-title block-mb-8'>
                <span class='icon iconfont iconcomplaint'>客户投诉</span>
                {isMine ? mineOpera(item) : underlingsOpera(item)}
              </h4>
              <p class='satisfaction-received-item__text block-mb-10'>{item.content}</p>
              {item?.fileUrls ? (
                <h4 class='satisfaction-received-item__desc-title h4-p-2'>上传文件</h4>
              ) : (
                ''
              )}
              {item?.fileUrls?.map((files, index) => {
                return (
                  <tojoy-file
                    key={index}
                    data={{ type: files.fileType, fileName: files.fileName, url: files.fileUrl }}
                  />
                )
              })}
              <p class='submit-date'>{item.createTime}</p>
              {item?.appealOutputs?.length ? (
                <h4 class='satisfaction-received-item__desc-title h4-p-1'>申诉记录</h4>
              ) : (
                ''
              )}
              {item?.appealOutputs?.map((da, index) => {
                return (
                  <div class='appeal-list'>
                    <p class='satisfaction-received-item__text'>{da.content}</p>
                    <p class='submit-date'>{da.crateTime}</p>
                  </div>
                )
              })}
              {item?.complaintAuditOutputs?.map((cr, index) => {
                return cr.state === 1 ? (
                  <div class='rete-records'>
                    <h4 class='satisfaction-received-item__desc-title block-mb-10'>
                      {cr.userName}评分记录
                      <span class='score'>
                        {cr?.state === 1 ? handleScoreShowText(cr?.score, false) : ''}
                      </span>
                    </h4>
                    <p class='satisfaction-received-item__text'>{cr?.content}</p>
                    <p class='submit-date'>{cr?.createTime}</p>
                  </div>
                ) : (
                  ''
                )
              })}
              {/** 审批按钮集合 */ underlingsApproveBtn(item)}
            </div>
          )
        })}
      </article>
    ) : (
      ''
    )
  }
}

export default complaintHtml
