<script lang="jsx">
import approveLogo from '@/assets/images/approveIng.png'
const defaultAvatar = require('@/assets/images/profile.png')
export default {
  name: 'small-user-card',
  props: {
    data: {
      type: Object,
      default: () => {}
    },
    size: {
      type: [Number, String],
      default: 38
    },
    font: {
      type: [Number, String],
      default: 14
    },
    border: {
      type: Boolean,
      default: false,
    },
    triggerData: {
      type: Object,
      default: () => {
        return {
          'all': true
        }
      },
    }
  },
  data() {
    return {
      defaultAvatar
    }
  },
  methods: {
    handleClick(currentClickProp, data) {
      if(!this.triggerData.all) {
        const _new = {
          ...this.triggerData,
          'avatar': true
        }
        const _prop = _new[currentClickProp]
        if(_prop) {
          this.$emit('user-card', data)
        }
      } else {
        this.$emit('user-card', data)
      }
    }
  },
  render(h) {
    const { data, size, defaultAvatar, font, border, triggerData } = this
    return (
      <div class="small-user-card">
        {
          <div onClick={() => this.handleClick('avatar', data)}>
            <el-avatar
              style={{fontSize: font + 'px'}}
              class={['small-user-card__avatar ', data.avatar ? '' : ' no-avatar ', border ? ' border ' : '']}
              size={size}
              src={data.avatar}
            >
              {data.username?.slice(-2)}
            </el-avatar>
          </div>
        }

        <div class={['small-user-card__user ', size > 38 ? 'small-user-card__user--big':'' ]}>
          <div class={['username', (triggerData.all || triggerData.username) ? 'is-click' : '']} onClick={() => this.handleClick('username', data)}>{data.username}
          <img class={data.approveState === 1 ? 'approveIng' : 'noImg'}  src={data.approveState === 1 ? approveLogo : '/'} alt=""/>
          </div>
          <p class={['position', (triggerData.all || triggerData.position) ? 'is-click' : '']} onClick={() => this.handleClick('position', data)}>{data.position}</p>
          {
            data.organization && (<p class={['organization', (triggerData.all || triggerData.organization) ? 'is-click' : '']} onClick={() => this.handleClick('organization', data)}>{data.organization}</p>)
          }
        </div>
        <div class='small-user-card__right'>
          {
            this.$slots.right
          }
        </div>
      </div>
    )
  }
}
</script>
<style lang="scss">
.small-user-card {
  display: flex;
  flex-shrink: 0;
  .el-avatar > img {
    width: 100%;
    height: 100%;
  }
  &__avatar {
    cursor: pointer;
    margin-right: 10px;
    background: $white;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
    &.border {
      border: 2px solid #fff;
    }
    &.no-avatar {
      background: $blue;
    }
  }
  &__user {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    .username {
      font-size: 15px;
      font-weight: 400;
      color: $lightblack;
      line-height: 15px;
      margin: 3.5px 0;
      margin-top: -2px;
      display: flex;
      .approveIng {
        width: 60px;
        height: 16px;
        margin-left: 5px;
        display: block;
      }
      .noImg {
        display: none;
      }
    }
    .username,
    .organization,
    .position {
      &.is-click {
        cursor: pointer;
      }
    }
    p {
      font-size: 12px;
      font-weight: 400;
      color: $grey;
      line-height: 12px;
      margin: 3.5px 0;
      &:last-of-type {
        margin-bottom: 0;
      }
      &.organization {
        line-height: 14px;
      }
    }
    &--big {
      .username {
        font-size: 16px;
      }
      p {
        font-size: 13px;
      }
    }
  }
  &__right {
    flex: none;
    text-align: right;
  }
}
</style>
