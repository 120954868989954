import { cloneDeep } from 'lodash'
/**
 * 满意度-下属满意度
 * @param {*} data Object
 */
const handleTeamSatisfactionData = (data = {}) => {
  let _resultmap = {}
  _resultmap.total = data.total

  let _list = []
  if (!data?.list) return false
  for (let one = 0; one < data?.list?.length; one++) {
    const oneItem = data.list[one]
    let _outputs = []
    for (let outputs = 0; outputs < oneItem?.outputs?.length; outputs++) {
      const outputsItem = oneItem.outputs[outputs]
      let _userList = [],
        _customerList = []
      for (let ur = 0; ur < outputsItem?.userList?.length; ur++) {
        const urItem = outputsItem.userList[ur]
        _userList.push({
          title: urItem.showName,
          rate: urItem.starNumPercent
        })
      }
      for (let cr = 0; cr < outputsItem?.customerList?.length; cr++) {
        const crListItem = outputsItem.customerList[cr]
        _customerList.push({
          title: crListItem.showName,
          rate: crListItem.starNumPercent
        })
      }
      _outputs.push({
        ...outputsItem,
        userid: outputsItem.userId,
        username: outputsItem.userName,
        position: outputsItem.job,
        customerList: _customerList,
        userList: _userList
      })
    }
    _list.push({
      ...oneItem,
      outputs: _outputs
    })
  }
  _resultmap.list = _list
  return _resultmap
}

/**
 * 满意度-下属满意度-待我审批列表
 * @param {*} data
 */
const handleApproveList = (data = {}) => {
  let _list = []
  for (let one = 0; one < data?.list?.length; one++) {
    let oneItem = data.list[one]
    let _users = []
    for (let u = 0; u < oneItem?.users?.length; u++) {
      let userItem = oneItem?.users[u]
      _users.push({
        avatar: userItem.avatar,
        position: userItem.job,
        userid: userItem.userId,
        username: userItem.userName,
        organization: userItem.department
      })
    }
    _list.push({
      avatar: oneItem.avatar,
      position: oneItem.job,
      userid: oneItem.userId,
      username: oneItem.userName,
      data: _users,
      version: oneItem.version
    })
  }

  return {
    list: _list,
    total: data.total
  }
}

/**
 * 满意度-下属满意度-投诉处理列表
 */
const handleComplaint = (data = {}) => {
  let _list = []
  for (let one = 0; one < data?.list?.length; one++) {
    let _outputs = []
    let oneItem = data.list[one]
    for (let outputs = 0; outputs < oneItem?.outputs?.length; outputs++) {
      let outputsItem = oneItem?.outputs[outputs]
      _outputs.push({
        avatar: outputsItem.avatar,
        position: outputsItem.job,
        score: outputsItem.score,
        state: outputsItem.state,
        userid: outputsItem.userId,
        username: outputsItem.userName,
        complaintOutputs: outputsItem.complaintOutputs
      })
    }
    _list.push({
      evaluateDay: oneItem.evaluateDay,
      outputs: _outputs
    })
  }
  return {
    list: _list,
    total: data.total
  }
}

/**
 * 满意度-下属满意度-感动处理列表
 */
const handleTouch = (data = {}) => {
  let _list = []
  for (let one = 0; one < data?.list?.length; one++) {
    let _outputs = []
    let oneItem = data.list[one]
    for (let outputs = 0; outputs < oneItem?.outputs?.length; outputs++) {
      let outputsItem = oneItem?.outputs[outputs]
      _outputs.push({
        avatar: outputsItem.avatar,
        position: outputsItem.job,
        score: outputsItem.score,
        state: outputsItem.state,
        userid: outputsItem.userId,
        username: outputsItem.userName,
        touchOutputs: outputsItem.touchOutputs
      })
    }
    _list.push({
      evaluateDay: oneItem.evaluateDay,
      outputs: _outputs
    })
  }
  return {
    list: _list,
    total: data.total
  }
}

/**
 * 满意度-我的满意度-待我评价-列表
 * @param {*} data Object
 */
const handleComment = (data = {}) => {
  let _list = []
  if (!data.outputs) return false
  for (let one = 0; one < data.outputs?.length; one++) {
    let oneItem = data.outputs[one]
    let _userList = []
    for (let ur = 0; ur < oneItem.dimensionOutputs?.length; ur++) {
      let urItem = oneItem.dimensionOutputs[ur]
      _userList.push({
        id: urItem.id,
        title: urItem.showName,
        rate: 0
      })
    }
    _list.push({
      ...oneItem,
      userid: oneItem.userId,
      username: oneItem.userName,
      position: oneItem.job,
      organization: oneItem.department,
      rateList: cloneDeep(_userList)
    })
  }
  return { outputs: _list }
}

/**
 * 满意度-我的满意度-待我评价-批量提交
 * @param {*} data
 */
const handleCommentSubmit = (data = {}) => {
  let _list = []
  if (!data.outputs) return false
  for (let i = 0; i < data.outputs?.length; i++) {
    const _curItem = data.outputs[i]
    let _rates = []
    for (let m = 0; m < _curItem.rateList?.length; m++) {
      const _rateItem = _curItem.rateList[m]
      _rates.push({
        dimensionConfigId: _rateItem.id,
        showName: _rateItem.title,
        starNum: _rateItem.rate
      })
    }
    _list.push({
      dimensionInputs: _rates,
      evaluateUserDepartment: _curItem.organization,
      evaluateUserId: _curItem.userid,
      evaluateUserJob: _curItem.position,
      evaluateUserName: _curItem.username,
      remark: _curItem.suggest
    })
  }
  return { inputs: [..._list] }
}

/**
 * 满意度-我的满意度-已收到的
 * @param {*} data
 */
const handleComplete = (data = {}) => {
  let _list = []
  if (!data.list) return false
  for (let one = 0; one < data?.list.length; one++) {
    let oneItem = data.list[one]
    let _listOutputs = []
    for (let outputs = 0; outputs < oneItem?.listOutputs?.length; outputs++) {
      let outputsItem = oneItem.listOutputs[outputs]
      let _rateList = []
      for (let rate = 0; rate < outputsItem?.dimensionOutputs?.length; rate++) {
        let rateItem = outputsItem?.dimensionOutputs[rate]
        _rateList.push({
          title: rateItem.showName,
          rate: rateItem.starNum
        })
      }
      _listOutputs.push({
        ...outputsItem,
        userid: outputsItem.evaluateUserId,
        username: outputsItem.evaluateUserName,
        position: outputsItem.evaluateUserJob,
        organization: outputsItem.evaluateUserDepartment,
        rateList: _rateList
      })
    }
    _list.push({
      evaluateDay: oneItem.evaluateDay,
      listOutputs: _listOutputs
    })
  }
  return {
    list: _list,
    total: data.total
  }
}

export const BF_TYPE = {
  teamList: 'TEAM_STATISFACTION_LIST',
  commontList: 'MAIN_STATISFACTION_COMMENT',
  commontSubmit: 'MAIN_STATISFACTION_COMMENT_SUBMIT',
  completeList: 'MAIN_STATISFACTION_COMPLETE',
  approveList: 'MAIN_STATISFACTION_APPROVE_LIST',
  complaintList: 'TEAM_COMPLAINT_LIST',
  touchList: 'TEAM_TOUCH_LIST'
}

const _map = new Map()
_map.set(BF_TYPE.teamList, handleTeamSatisfactionData)
_map.set(BF_TYPE.commontList, handleComment)
_map.set(BF_TYPE.commontSubmit, handleCommentSubmit)
_map.set(BF_TYPE.completeList, handleComplete)
_map.set(BF_TYPE.approveList, handleApproveList)
_map.set(BF_TYPE.complaintList, handleComplaint)
_map.set(BF_TYPE.touchList, handleTouch)

export const handleDataBF = (data = {}, type) => {
  return _map.get(type)(data)
}
