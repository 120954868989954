<script lang="jsx">
import { RATE_DESC } from '@/config/config'
const imgStar = require('@/assets/images/star.svg')
const imgStarO = require('@/assets/images/star-o.svg')
export default {
  name: 'tojoy-rate',
  model: {
    prop: 'rate',
    event: 'change',
  },
  props: {
    rate: {
      type: [String, Number],
      default: 0
    },
    title: {
      type: String,
      default: '',
    },
    desc: {
      type: Array,
      default: () => [],
    },
    count: {
      type: Number,
      default: 5
    },
    size: {
      type: [Number, String],
      default: 19
    },
    disabled: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    rateValue: {
      get() {
        return Number(this.rate)
      },
      set(val) {
        this.$emit('change', val)
      }
    },
  },
  methods: {
    handleChange(rate) {
      this.$emit('changes', rate)
    },
  },
  render(h) {
    let {disabled} = this
    return (
      <div class="tojoy-rate">
        <div class={`tojoy-rate__title tojoy-rate__title-${disabled ? 'no': 'yes'}`}>{this.title}：</div>
        <el-rate
          v-model={this.rateValue}
          max={this.count}
          icon-classes={['icon-class iconfont star-check iconstar', 'icon-class iconfont star-check iconstar', 'icon-class iconfont star-check iconstar', 'icon-class iconfont star-check iconstar', 'icon-class iconfont star-check iconstar']}
          disabled-void-icon-class='icon-class iconfont iconstar disabled-icon-star'
          void-icon-class='icon-class star-o'
          allow-half={this.disabled}
          disabled={this.disabled}
          text-color={this.rateValue < 4 ? '#999999' : '#FF526A'}
          texts={RATE_DESC}
          show-text={!this.disabled}
          onChange={(rate) => this.handleChange(rate)}
        />
      </div>
    )
  },
}
</script>
<style lang="scss">
.tojoy-rate {
  display: flex;
  align-items: center;
  .icon-class {
    font-size: 15px;
    // width: 15px;
    // height: 14px;
  }
  .disabled-icon-star {
    color: #eee !important;
  }
  .star-check {
    color: $red !important;
  }
  .star {
    // background: url('../../../assets/images/star.svg') no-repeat center;
    // background-size: cover;
  }
  .star-o {
    background: url('../../../assets/images/star-o.svg') no-repeat center;
    background-size: cover;
  }
  &__title {
    font-size: 12px;
    font-weight: 400;
    line-height: 12px;
    // margin-right: 10px;
    margin-top: 1px;
    width: 76px;
    &-yes {
      color: $lightblack;
    }
    &-no {
      color: #bbb;
    }
  }
  .el-rate {
    height: auto;
    // line-height: 0;
    outline: none;
    &__icon {
      margin-right: 15px;
      display: flex;
      align-items: center;
      transition: none;
    }
    &__text {
      font-size: 12px;
      font-weight: 400;
      color: $grey;
      line-height: 12px;
    }
  }
}
</style>
