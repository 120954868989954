<script lang="jsx">
const FILE_TYPE = {
  'image': 'iconimg',
  'pdf': 'iconP',
  // 'zip': 'iconicon-test',
  'doc': 'iconW',
  'docx': 'iconW',
  'xls': 'iconX',
  'xlsx': 'iconX',
  'txt': 'iconT'
}
export default {
  name: 'tojoy-file',
  props: {
    isShow:{
      type:Boolean,
       default:false
    },
    index:{
       type:Number,
       default:0
    },
    data: {// type, fileName, url
      type: Object,
      default: () => {}
    }
  },
  computed: {
    currentType() {
      const _t = ['jpg', 'jpeg', 'png', 'gif'].includes(this.data?.type?.toLowerCase()) ? 'image' :  this.data?.type
      return FILE_TYPE[_t] || 'iconicon-test'
    }
  },
  methods: {
    handleDownload () {
      let _downloadAddress = `${this.data?.url}`
      if (_downloadAddress) {
        const downloadElement = document.createElement('a')
        downloadElement.href = _downloadAddress
        downloadElement.target='_blank'
        downloadElement.download = _downloadAddress
        document.body.appendChild(downloadElement)
        downloadElement.click()
        document.body.removeChild(downloadElement)
        window.URL.revokeObjectURL(_downloadAddress)
      }
    },
    handleRemove () {
      this.$emit('remove', this.index,this.data)
    },
  },
  render(h) {
    const {currentType, data:{fileName, url} } = this
    return (
      <div class="tojoy-file">
        <span class={['tojoy-file-types icon iconfont ', currentType]}></span>
        <p class="tojoy-file-name" onClick={() => this.handleDownload()}>{fileName ?? '文件'}</p>
        {this.isShow?<i class="el-icon-delete" onClick={() => this.handleRemove()}></i>:''}
      </div>
    )
  },
}
</script>
<style lang="scss">
.tojoy-file {
  height: 38px;
  border: 1px solid $linecolor;
  display: flex;
  align-items: center;
  @include bg-hover-color();
  &-types {
    width: 38px;
    height: 38px;
    line-height: 38px;
    background: $blue;
    color: $white;
    text-align: center;
    vertical-align: middle;
    margin-right: 10px;
    &.iconW {
      background: $blue;
    }
    &.iconP {
      background: $red;
    }
    &.iconicon-test {
      background: $lighterblue;
    }
    &.iconX {
      background: $green;
    }
    &.iconT {
      background: $purple;
    }
    &.iconimg {
      background: $yellow;
    }
  }
  &-name {
    flex: 1;
    font-size: 12px;
    font-weight: 400;
    color: $lightblack;
    line-height: 38px;
    cursor: pointer;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .el-icon-delete {
    margin-right: 10px;
    color: $darkgrey;
    cursor: pointer;
    &:hover {
      color: $blue;
    }
  }
}
</style>
