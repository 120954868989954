/**
 * 分数格式化处理
 * @param {String} score
 * @param {Boolean} isPlus 正-true 负-false
 */
export const handleScoreShowText = (score = 0, isPlus = true) => {
  if (!score || !parseFloat(score)) return `0分`

  const mark = isPlus ? '+' : '-'
  return `${mark}${score}分`
}
