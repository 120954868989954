export default {
  watch: {
    '$route': { // 兼容-点击浏览器回退按钮能够重新刷新当前组件数据，query是放在hash后面的，hash改变不会引起浏览器刷新
      handler(newVal, oldVal) {
        const _moduleName = newVal?.query?.moduleName
        if(_moduleName) {
          this.tabName = _moduleName
          this.handleTabClick({name: _moduleName})
        }
      }
    }
  },
}