<script lang="jsx">
const SCHEMA_CLASSANEM = {
  'time': 'tojoy-card--horizontal',
  'people': 'tojoy-card--vertical',
}

export default {
  name: 'tojoy-card',
  props: {
    data: {
      type: Object,
      default: () => {}
    },
    schema: {
      type: String, // time | people
      default: 'time'
    }
  },
  computed: {
    currentClassName() {
      return `tojoy-card ${SCHEMA_CLASSANEM[this.schema]}`
    },
  },
  methods: {
    schemaHtml() {
      const time = () => {
        const {data:{ time, unit }, currentClassName, $slots} = this
        return (
          <div class={[currentClassName]}>
            <div class="tojoy-card--left">
              {
                !$slots.leftcard ? (
                  <div class="tojoy-card--left__wrap">
                    <span class="tojoy-card--left__time">{ time }</span>
                    <i class="tojoy-card--left__unit">{ unit }</i>
                  </div>
                ) : $slots.leftcard
              }

            </div>
            <div class="tojoy-card--right">
              {
                this.$slots.rightcard
              }
            </div>
          </div>
        )
      }

      const people = () => {
        const {currentClassName, $slots} = this
        return (
          <div class={[currentClassName]}>
            <div class="tojoy-card--up">
              {
                $slots.upcard
              }
            </div>
            <div class="tojoy-card--down">
              {
                $slots.downcard
              }
            </div>
          </div>
        )
      }

      return this.schema === 'time' ? time() : people()
    },
  },
  render(h) {
    return (this.schemaHtml())
  }
}
</script>

<style lang="scss">
.tojoy-card {
  flex: 1;
  display: flex;
  &--vertical {
    flex-direction: column;
  }
  &--horizontal {
    flex-direction: row;
  }
  &--left {
    &__wrap {
      flex: none;
      width: 46px;
      height: 46px;
      background: $white;
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.04);
      border-radius: 3px;
      border: 1px solid $linecolor;
      text-align: center;
      line-height: 10px;
      padding-top: 12px;
      display: flex;
      flex-direction: column;
    }
    &__time {
      font-size: 15px;
      font-weight: 600;
      color: $lightblack;
      line-height: 10px;
    }
    &__unit {
      font-size: 12px;
      font-weight: 400;
      color: $grey;
      line-height: 22px;
      transform: scale(0.8);
    }
  }
  &--right {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
  .item {
    &-card {
      // display: flex;
      // border-bottom: 1px solid $linecolor;
      // padding: 15px 15px 0;
    }
    // &-left {
    //   &__card {
    //     flex: none;
    //     width: 46px;
    //     height: 46px;
    //     background: $white;
    //     box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.04);
    //     border-radius: 3px;
    //     border: 1px solid $linecolor;
    //     text-align: center;
    //     line-height: 10px;
    //     padding-top: 12px;
    //     display: flex;
    //     flex-direction: column;
    //     &-time {
    //       font-size: 15px;
    //       font-weight: 600;
    //       color: $lightblack;
    //       line-height: 10px;
    //     }
    //     &-unit {
    //       font-size: 12px;
    //       font-weight: 400;
    //       color: $grey;
    //       line-height: 22px;
    //       transform: scale(0.8);
    //     }
    //   }
    // }
    // &-right {
    //   &__data {
    //     display: flex;
    //     flex-direction: column;
    //     flex: 1;
    //   }
    // }
  }
}
</style>
